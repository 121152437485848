import React, { useState, useEffect, useRef } from 'react';
import Layout from './Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import captions from './ai-image-captions.json';

const Page = ({ imageData, page, totalPages, onNext }) => {
  const [showNextButton, setShowNextButton] = useState(false);
  const [isCorrectGuess, setIsCorrectGuess] = useState(null);
  const [caption, setCaption] = useState(null);
  const [sourceUrl, setSourceUrl] = useState(null);
  const [imageIsAiGenerated, setImageIsAiGenerated] = useState(null);

  // Create references for the image and caption
  const imageRef = useRef(null);
  const captionRef = useRef(null);

  useEffect(() => {
    // Reset showNextButton when the page changes
    setShowNextButton(false);
    setIsCorrectGuess(null);
    setCaption(null);
    setSourceUrl(null);
  }, [page]);

  const getRandomAiCaption = () => {
    const randomIndex = Math.floor(Math.random() * captions.length);
    return captions[randomIndex];
  };


  const handleChoice = async (choice) => {
    const payload = {
      guesses: [{
        "image_id": imageData.id,
        "user_guess": choice
      }]
    };

    try {
      const response = await fetch('https://backend.realorai.com/api/batchupdateimagestats', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      if (data && data.results && Array.isArray(data.results) && data.results.length > 0) {
        const isCorrect = data.results[0].is_correct_guess;
        const isAiGenerated = (choice && isCorrect) || (!choice && !isCorrect);
        if (data.results[0].captions) {
          setCaption(data.results[0].captions);
        }
        if (data.results[0].source) {
          setSourceUrl(data.results[0].source);  // Store the source URL if it exists
        }
        setIsCorrectGuess(isCorrect); // store the result in the state
        setImageIsAiGenerated(isAiGenerated);
        setShowNextButton(true); // show the 'Next' button
      }
    } catch (error) {
      console.error('Error updating image stats:', error);
    }
  };

  const handleNextClick = () => {
    onNext(isCorrectGuess); // move to the next page
    setShowNextButton(false);
    setIsCorrectGuess(null); // reset the correctness for the next image
 
    // Track the event with Google Analytics using the gtag function
    window.gtag('event', 'Clicked Next Image', {
      'event_category': 'Gallery',
      'event_label': `Page ${page}`
  });
  };

  const handleImageLoad = () => {
    // Adjust caption width based on the image width once it's loaded
    if (imageRef.current && captionRef.current) {
      const imageWidth = imageRef.current.getBoundingClientRect().width;
      captionRef.current.style.maxWidth = `${imageWidth}px`;
    }
  };
  
  const choiceClass = isCorrectGuess === null ? 'choice-symbol' : isCorrectGuess ? 'choice-symbol choice-correct' : 'choice-symbol choice-wrong';  

  function handleImageError(e) {
    console.error("Image loading error for source:", e.target.src);
  }

  return (
    <Layout progress={page} onNext={handleNextClick} showNext={showNextButton} totalPages={totalPages}>
      <div className="row">
        {/* Image on the Left */}
        <div className="col-12 col-lg-6 text-center image-container"> 
          <img 
            src={imageData.image} 
            alt="Distinguish Real vs. AI-generated visuals" 
            className="img-fluid" 
            onError={handleImageError} 
            onLoad={handleImageLoad}
            ref={imageRef} 
          />
          <div className={choiceClass}></div>
          <div className="caption" ref={captionRef}>
            {showNextButton && (
              imageIsAiGenerated ? (
                <span className="ai-caption">{getRandomAiCaption()}</span>
              ) : (
                <a href={sourceUrl} target="_blank" rel="noopener noreferrer" className="image-link">
                  <span className="title">
                    {caption.title.length > 140 ? "Image" : caption.title}
                  </span> {', '}
                  <span className="artist">{caption.artist}</span> {', '}
                  <span className="year">{caption.year}</span>
                </a>
              )
            )}
          </div>
        </div>

        {/* Content on the Right */}
        <div className="col-12 col-lg-6 text-center">
          <div className="buttons">
            {showNextButton ? (
              <button className="btn btn-lg btn-primary" onClick={handleNextClick}>Next</button>
            ) : (
              <>
                <button className="btn btn-lg btn-primary mr-2" onClick={() => handleChoice(false)}>Real</button>
                <button className="btn btn-lg btn-primary" onClick={() => handleChoice(true)}>AI</button>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
);
  
};

export default Page;
