import React from 'react';
import { Link } from 'react-router-dom';

const Layout = ({ children, progress, totalPages }) => (
  <div className="layout container-fluid">
    <header className="row">
      <div className="col-12 position-relative">
        <video className="background-video d-none d-sm-block" playsInline autoPlay loop muted>
          <source src="https://cosmic-canvas.sfo3.cdn.digitaloceanspaces.com/option-header.mp4" type="video/mp4" />
        </video>
        <header>
          <h1 className="mobile">Can you tell?</h1>
          <h1 className="desktop">Can You Tell What's Real In A World Crafted By AI?</h1>
          <h2 className="visually-hidden">Explore the Challenge of Distinguishing Real from AI-Generated Images</h2>
          <h3 className="visually-hidden">Authenticity Test: Real vs. AI-Generated Visuals</h3>
          <h3 className="visually-hidden">Participate in Our Interactive Tests to See if You Can Identify AI Creations</h3>
        </header>

      </div>
    </header>
    <div className="row content">
      <div className="col-12">
        {children}
      </div>
    </div>
    <div className="footer">
      <Link className="contact-link" to="/contact">Contact</Link>
      <a href="https://www.producthunt.com/posts/real-or-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-real&#0045;or&#0045;ai" target="_blank" rel="noopener noreferrer" className="product-hunt-badge">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=421437&theme=light" alt="Real&#0032;Or&#0032;AI - Teach&#0032;teams&#0032;to&#0032;spot&#0032;AI&#0032;images | Product Hunt" width="250" height="54" />
      </a>
      <p className="progress-counter">{progress}/{totalPages}</p>
    </div>
  </div>
);

export default Layout;
